.c-collection-editors {
	@include u-list-reset;
}

.c-collection-editors__ampersand,
.c-collection-editors__comma {
	display: none;
}

.js .c-collection-editors__item,
.js .c-collection-editors__ampersand,
.js .c-collection-editors__comma {
	display: inline-block;
}

.js .c-collection-editors__name-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex: 0 1 auto;
}

// This can be removed if these link styles get added to Brand Context default links styles
.c-collection-editors__name a {
	@include u-word-wrap;
	text-decoration: underline;
	text-decoration-thickness: .0625rem;
	text-underline-offset: .08em;

	&:hover {
		text-decoration: underline;
		text-decoration-thickness: .3875rem;
		text-underline-offset: .08em;
	}
}

.c-collection-editor__popup h3 {
	display: none;
}

.c-collection-editors__image {
	margin-right: spacing(16);
	margin-bottom: spacing(8);
	width: 64px;
	height: 64px;
	border-radius: 50%;
	object-fit: cover;
}

.js .c-popup.c-collection-editor__popup {
	font-size: 1rem;
}

.js .c-collection-editor__popup h3 {
	display: block;
	margin-bottom: spacing(8);
}

.js .c-collection-editor__popup p {
	margin: 0;
	white-space: pre-line;
}

