.c-site-messages--nature-briefing-email-variant {
	padding-top: 15px;
	padding-bottom: 15px;
	box-sizing: border-box;

	.visually-hidden,
	.js .js-visually-hidden {
		width: 1px;
		height: 1px;
		position: absolute !important;
		clip: rect(1px, 1px, 1px, 1px);
	}

	&.serif,
	.serif {
		font-family: "Harding", "Palatino", "Times", "Times New Roman", serif;
	}

	&.sans-serif,
	.sans-serif {
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
	}

	.content {
		max-width: 1280px;
		margin: 0 auto;
	}

	.box-sizing {
		box-sizing: border-box;
	}

	.pin-right {
		float: right;
	}

	.text13 {
		font-size: .8125rem;
	}

	.text14 {
		font-size: .875rem;
	}

	.block {
		display: block;
	}

	.strong {
		font-weight: bold;
	}

	.tighten-line-height {
		line-height: 1.4;
	}

	.extra-tight-line-height {
		line-height: 1.3;
	}

	.border-gray {
		border-style: solid;
		border-width: 0;
		border-color: #999;
	}

	.border-all-1 {
		border-width: 1px;
	}

	.pa10 {
		padding: 10px;
	}

	.grid {
		float: left;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.grid-inline,
	.grid {
		margin-right: 3.2%;
	}

	.last {
		margin-right: 0;
	}

	.grid-1 {
		width: 5.4%;
	}

	.grid-2 {
		width: 14%;
	}

	.grid-3 {
		width: 22.6%;
	}

	.grid-4 {
		width: 31.2%;
	}

	.grid-5 {
		width: 39.8%;
	}

	.grid-6 {
		width: 48.4%;
	}

	.grid-7 {
		width: 57%;
	}

	.grid-8 {
		width: 65.6%;
	}

	.grid-9 {
		width: 74.2%;
	}

	.grid-10 {
		width: 82.8%;
	}

	.grid-11 {
		width: 91.4%;
	}

	.grid-12 {
		width: 100%;
	}

	.grid-left-1 {
		margin-left: 8.6%;
	}

	.grid-right-1 {
		margin-right: 8.6%;
	}

	.grid-left-2 {
		margin-left: 17.2%;
	}

	.grid-right-2 {
		margin-right: 17.2%;
	}

	.grid-left-3 {
		margin-left: 25.8%;
	}

	.grid-right-3 {
		margin-right: 25.8%;
	}

	.grid-left-4 {
		margin-left: 34.4%;
	}

	.grid-right-4 {
		margin-right: 34.4%;
	}

	.grid-left-5 {
		margin-left: 43%;
	}

	.grid-right-5 {
		margin-right: 43%;
	}

	.grid-left-6 {
		margin-left: 51.6%;
	}

	.grid-right-6 {
		margin-right: 51.6%;
	}

	.grid-left-7 {
		margin-left: 60.2%;
	}

	.grid-right-7 {
		margin-right: 60.2%;
	}

	.grid-left-8 {
		margin-left: 68.8%;
	}

	.grid-right-8 {
		margin-right: 68.8%;
	}

	.grid-left-9 {
		margin-left: 77.4%;
	}

	.grid-right-9 {
		margin-right: 77.4%;
	}

	.grid-left-10 {
		margin-left: 86%;
	}

	.grid-right-10 {
		margin-right: 86%;
	}

	.grid-left-11 {
		margin-left: 94.6%;
	}

	.grid-right-11 {
		margin-right: 94.6%;
	}

	.grid-1of4 {
		width: 25%;
	}

	.grid-1of3 {
		width: 33.3334%;
		width: calc(100% / 3);
	}

	.grid-1of2 {
		width: 50%;
	}

	.grid-3of4 {
		width: 75%;
	}

	.grid-1of5 {
		width: 20%;
	}

	.grid-2of5 {
		width: 40%;
	}

	.grid-3of5 {
		width: 60%;
	}

	.grid-4of5 {
		width: 80%;
	}

	.grid-split-margin-left {
		margin-left: 1.6%;
		padding-left: 1.6% !important;
	}

	.grid-split-margin-right {
		margin-right: 1.6%;
		padding-right: 1.6% !important;
	}
}

.c-site-messages--nature-briefing {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	background-color: #069;
	color: #fff;
	-webkit-box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.2);
	-moz-box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.2);
	box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.2);
	-webkit-transform: translateY(100%);
	-ms-transform: translateY(100%);
	transform: translateY(100%);
	-webkit-transition: background-color 1s ease-in-out 1.2s, color 1s ease-in-out 1s, -webkit-transform 0.6s ease-in-out;
	-o-transition: transform 0.6s ease-in-out, background-color 1s ease-in-out 1.2s, color 1s ease-in-out 1s;
	transition: transform 0.6s ease-in-out, background-color 1s ease-in-out 1.2s, color 1s ease-in-out 1s, -webkit-transform 0.6s ease-in-out;
	border-bottom: none;
	z-index: 2;
}

.c-site-messages--nature-briefing .icon--inline {
	position: relative;
	border: none;
	border-radius: 0;
	padding: 10px;
}

.c-site-messages--nature-briefing .icon--inline svg {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	width: 24px;
	height: 24px;
}

.c-site-messages--nature-briefing .icon--inline path {
	transition: transform 0.6s ease-in-out, fill 1s ease-in-out 1.2s, color 1s ease-in-out 1s, -webkit-transform 0.6s ease-in-out;
	fill: rgba(255, 255, 255, 0.5);
}

.c-site-messages--nature-briefing .c-site-messages__close {
	border: none;
	padding: 0;
}

.c-site-messages--nature-briefing .c-site-messages__close-container {
	width: auto;
	position: relative;
	text-align: right;
	display: inline-block;
}

.c-site-messages--nature-briefing .c-site-messages__close-container svg {
	width: 25px;
	height: 25px;
}

.c-site-messages--nature-briefing.c-site-messages .c-site-messages__close {
	background: transparent;
}

@media only screen and (min-width: 960px) {
	.c-site-messages--nature-briefing.c-site-messages .c-site-messages__close {
		margin-top: 12px;
	}

	.c-site-messages--nature-briefing-email-variant.c-site-messages .c-site-messages__banner-large .c-site-messages__close {
		margin-top: 0;
	}
}

.c-site-messages__banner-large .c-site-messages__close-container {
	width: 100%;
	float: right;
	margin-top: -5px;
}

.c-site-messages__banner-large .c-site-messages__form-container {
	float: none;
	width: 100%;
}

@media only screen and (min-width: 768px) {
	.c-site-messages__banner-large .c-site-messages__close-container {
		width: 30px;
	}

	.c-site-messages__banner-large .c-site-messages__form-container {
		float: left;
		width: calc(100% - 30px);
	}
}

.c-site-messages--nature-briefing .c-site-messages__close {
	vertical-align: top;
}

.c-site-messages__banner-small .c-site-messages__content {
	display: inline-block;
	width: 89%;
}

.c-site-messages__banner-small .c-site-messages__close-container {
	position: relative;
	text-align: right;
	display: inline-block;
	width: 10%;
}

.c-site-messages--nature-briefing .nature-briefing__link {
	text-align: center;
	text-transform: none;
	border-radius: unset;
	background-color: #fff;
	border: 1px solid #fff;
	color: #069;
	white-space: normal;
	font-weight: 400;
	letter-spacing: 0.5px;
	padding: 10px 15px;
	display: inline-block;
	margin-top: 10px;
	transition: transform 0.6s ease-in-out, border 1s ease-in-out 1s, -webkit-transform 0.6s ease-in-out;
}

@media only screen and (min-width: 960px) {
	.c-site-messages--nature-briefing .nature-briefing__link {
		margin-left: 15px;
		margin-top: 0;
	}
}

.c-site-messages--is-visible {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	background-color: #fff;
	color: #222;
}

.c-site-messages--is-visible .icon--inline path {
	fill: #069;
}

.c-site-messages--is-visible .nature-briefing__link {
	border: 1px solid #069;
}

.c-site-messages--nature-briefing .c-site-messages__close {
	color: #069;
}

@media only screen and (max-width: 700px) {
	.u-hide-on-mobile {
		display: none;
	}
}

.nature-briefing-banner__email-input {
	width: 100%;
	margin-right: 1em;
	border-color: #222;
}

// bigger checkboxes inspired by GOV.UK Design System – https://design-system.service.gov.uk/components/checkboxes/
.nature-briefing-banner__checkbox-label {
	display: inline-block;
	cursor: pointer;
	position: relative;
	padding-left: 33px;
	max-width: 467px;

	&:before {
		content: "";
		position: absolute;
		top: 6px;
		left: 0;
		width: 24px;
		height: 24px;
		border: 1px solid currentColor;
		border-radius: 0;
		background: #fff;
	}

	&:after {
		content: "";
		position: absolute;
		top: 12px;
		left: 5px;
		width: 14px;
		height: 7px;
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
		border: solid;
		border-width: 0 0 2px 2px;
		border-top-color: transparent;
		opacity: 0;
		background: transparent;
	}
}

.nature-briefing-banner__checkbox-checkbox {
	font-size: 20px;
	cursor: pointer;
	position: absolute;
	z-index: 1;
	top: 4px;
	left: -2px;
	width: 28px;
	height: 28px;
	margin: 0;
	opacity: 0;
}

.nature-briefing-banner__checkbox-checkbox:checked+.nature-briefing-banner__checkbox-label:after {
	opacity: 1;
}

.nature-briefing-banner__checkbox-checkbox:focus+.nature-briefing-banner__checkbox-label:before {
	outline: 3px solid #fece3e;
}

.nature-briefing-banner__submit-button {
	border: 1px solid #fff;
	background: #069;
	color: #fff;
	line-height: 1.2;
	font-size: 1rem;
	padding: 7px 20px;
	text-align: center;
	width: auto;
	float: left;
	transition: border 1s ease-in-out 1s;
}

.nature-briefing-banner__submit-button:focus {
	outline: 3px solid #fece3e;
}

.nature-briefing-banner__submit-button:disabled {
	background-color: #777;
	color: #fff;
}

.c-site-messages--is-visible .nature-briefing-banner__submit-button {
	border: 1px solid #069;
}

.nature-briefing-banner__checkbox-label a {
	color: #fff;
	font-weight: bold;
	transition: color 1s ease-in-out 1s;

	&:hover {
		text-decoration: underline;
	}
}

.c-site-messages--is-visible .nature-briefing-banner__checkbox-label a {
	color: #222;
}

.c-site-messages__banner-large,
.c-site-messages__banner-small {
	margin: 0 auto;
	padding-left: 16px;
	padding-right: 16px;
}

.c-site-messages__banner-large {
	display: none; // only show big banner with form on larger screens
	max-width: 1280px;
}

.c-site-messages__banner-large:before,
.c-site-messages__banner-large:after {
	content: " ";
	display: table;
}

.c-site-messages__banner-large:after {
	clear: both;
}

.c-site-messages__banner-small {
	display: block;
}

@media only screen and (min-width: 641px) {

	.c-site-messages__banner-large {
		display: block;
	}

	.c-site-messages__banner-small {
		display: none;
	}
}

// 2020 redesign overrides – must be kept simulatenously with old stuff for A/B test

.c-site-messages.c-site-messages--nature-briefing-redesign-2020 {
	color: #fff;
	background-color: #29303b;
	background-repeat: no-repeat;
	background-position: right top;
	background-image: url('/static/images/icons/briefing-banner-illustration.png');
	-webkit-transition: -webkit-transform 0.6s ease-in-out;
	-o-transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
	padding-right: 120px;

	@media only screen and (max-width: 1040px) {
		background-position: 666px 0;
	}

	@media only screen and (max-width: 870px) {
		background-position: calc(85% + 374px - 120px) 0;
		padding-right: 15%;
	}

	@media only screen and (max-width: 640px) {
		background-position: calc(100% + 374px - 200px) 0;
		padding-right: 100px;

		.nature-briefing__link {
			display: block;
		}
	}

	@media only screen and (max-width: 480px) {
		background-image: none;
		padding-right: 0;

		.c-site-messages__banner-small {

			.c-site-messages__content {
				width: 100%;
			}

			.c-site-messages--nature-briefing__strapline {
				display: block;
				width: 89%;
				margin-top: 0;
			}
		}
	}

	.content {
		max-width: none;
	}

	.icon--inline path {
		transition: none;
		fill: #fff;
	}

	.c-site-messages__close-container {
		width: 25px;
		height: 25px;
		position: absolute;
		right: 10px;
		top: 10px;
		background: #29303b;
		border-radius: 12.5px;
		border: none;
	}

	.c-site-messages__close-container:hover {
		background: #fff;

		.c-site-messages__close svg path {
			fill: #069;
		}
	}

	.c-site-messages__banner-small .c-site-messages__close-container {
		text-align: center;
	}

	.c-site-messages--nature-briefing__strapline {
		margin-top: 10px;
		margin-bottom: 0;
		font-size: 14px;
		font-size: .875rem;

		@media only screen and (min-width: 1040px) {
			font-size: 16px;
			font-size: 1rem;
		}

		@media only screen and (min-width: 1200px) {
			font-size: 18px;
			font-size: 1.125rem;
		}
	}

	.nature-briefing-banner__checkbox-label {
		&:before {
			border-color: transparent;
			border-radius: 2px;
		}

		&:after {
			border-color: #222;
			border-top-color: transparent;
		}

		a {
			font-weight: normal;
			text-decoration: underline;
			color: #fff;
		}
	}

	.nature-briefing-banner__email-label {
		display: block;
		padding-left: 0;
	}

	.nature-briefing-banner__email-wrapper {
		margin-bottom: 10px;
	}

	.nature-briefing-banner__email-input {
		display: inline-block;
		float: none;
		width: 70%;
		max-width: 500px;
		border: 1px solid #fff;
		border-radius: 2px;
		padding: 8px 10px 7px;
		font-size: 15px;
		font-size: .9375rem;
		line-height: 15px;
		line-height: .9375rem;
		vertical-align: top;
		margin-right: 1%;
	}

	.nature-briefing-banner__submit-button {
		display: inline-block;
		float: none;
		transition: none;
		background-color: #29303b;
		border-radius: 2px;
		border: 1px solid #fff;
		padding: 8px 2%;
		font-size: 15px;
		font-size: .9375rem;
		line-height: 15px;
		line-height: .9375rem;
		vertical-align: top;
		width: 15%;
		min-width: 75px;
		max-width: 90px;
	}

	.nature-briefing-banner__submit-button:hover {
		color: #29303b;
		background-color: #fff;
	}

	.nature-briefing-banner__checkbox-wrapper {
		position: relative;
	}

	.nature-briefing__link {
		border: 1px solid #fff;
		transition: none;
		border-radius: 2px;
		background-color: #29303b;
		color: #fff;
	}

	.nature-briefing__link:hover {
		color: #29303b;
		background-color: #fff;
	}
}

.c-site-messages.c-site-messages--nature-briefing-cancer {
	border-top: 5px solid #1951a0;
}

.c-site-messages.c-site-messages--nature-briefing-transres {
	border-top: 5px solid #c6d531;
}
