.app-latest-issue-row {
	@include media-query('md') {
		display: flex;
	}
	padding: spacing(16);
	background-color: greyscale(80);
	margin: 0 auto 30px auto;
	.c-author-list {
		color: greyscale(20);
	}
}

.app-latest-issue-row__article-list {
	@include u-list-reset;
}

.app-latest-issue-row__main {
	@include media-query('md') {
		flex: 1 1 auto;
		padding-right: spacing(16);
	}
}

.app-latest-issue-row__side {
	@include media-query('md') {
		flex: 0 0 440px;
		max-width: 440px;
	}

	img {
		max-width: 100%;
		@include media-query('md', 'max') {
			display: block;
			margin: 0 auto;
		}
	}
}

.app-latest-issue-row__buttons {
	margin-top: spacing(16);
	@include media-query('lg') {
		display: flex;
		justify-content: space-between;
	}
}

.app-latest-issue-row__item {
	@include app-row-item();
}

.app-latest-issue-row__button {
	margin-bottom: spacing(16);
	@include media-query('lg') {
		flex: 0 0 50%;
		max-width: 50%;
		margin-bottom: spacing(0);
		&:not(:only-child):not(:last-child) {
			margin-right: spacing(8);
		}
	}
}

.app-latest-issue-row__image {
	box-shadow: 0 0 5px 0 rgba(128, 128, 128, 50%);
}

.app-latest-issue-row--by-3 {
	.app-latest-issue-row__button {
		@include media-query('lg') {
			flex: 0 0 32%;
			max-width: 32%;
		}
	}
}
