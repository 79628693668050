// Not refactored to elements - yet (DP)

// .hide is needed until ad company can remove `u-hide` from newer markup
.hide {
	display: none;
}

.container-type-banner-advert,
.leaderboard-container,
.leaderboard-or-billboard-container {
	display: none;
	font-size: 0;

	@include media-query('md') {
		display: block;
		padding: 8px 0;
	}
}

.leaderboard-container,
.leaderboard-or-billboard-container {
	background-color: #44444e;
	text-align: center;
}

.leaderboard-or-billboard-inner {
	display: inline-block;
	min-width: 728px;
	max-width: 100%;
	position: relative;
	min-height: 90px;
	line-height: 0;
	word-spacing: 0;
}

.ad-with-label {
	&::before,
	&::after {
		content: 'Advertisement';
		color: #fff;
		background: #999;
		font-size: 9px;
		line-height: 13px;
		font-family: "arial",sans-serif;
		text-transform: uppercase;
		position: absolute;
		word-spacing: 0;
		max-height: 100%;
		top: 0;
		bottom: 0;
	}

	&::before {
		border-right: 1px solid #fff;
		left: -15px;
		writing-mode: vertical-rl;
	}

	&::after {
		border-left: 1px solid #fff;
		right: -14px;
		writing-mode: vertical-lr;
	}
}

.native-ad {
	font-family: "Georgia";
}

.native-ad__title {
	font-family: "Georgia";
	font-weight: 400;
}
