.c-search {
	line-height: 1.3;
	font-size: 1.25rem;
	font-family: $context--font-family-sans;
	font-weight: normal;
}

.c-search--background {
	background-color: #eee;
	margin: 0 auto;
	padding: spacing(32) spacing(16);
}

.c-search--max-width {
	max-width: 720px;
}

.c-search__field {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}

.c-search__input-container {
	flex: 1 0 100%;
	margin-bottom: spacing(8);
}

.c-search__input-container--sm {
	@include media-query('sm') {
		flex: 999 1 auto;
		margin-right: spacing(16);
		margin-bottom: spacing(0);
	}
}

.c-search__input-container--md {
	@include media-query('md') {
		flex: 999 1 auto;
		margin-right: spacing(16);
		margin-bottom: spacing(0);
	}
}

.c-search__input-label {
	display: block;
	padding-left: spacing(0);
	margin-bottom: spacing(8);
}

.c-search__select-container {
	flex: 1 1 auto;
	margin-bottom: spacing(8);

	@include media-query('sm') {
		flex: 999 1 auto;
		margin-bottom: 0;
		margin-right: spacing(16);
	}

	@include media-query('md') {
		flex: 1 0 auto;
	}
}

.c-search__button-container {
	flex: 1 0 100%;

	@include media-query('sm') {
		flex: 1 0 auto;
	}
}

.c-search__button-container + * {
	padding-top: spacing(8);

	@include media-query('sm') {
		margin-left: spacing(16);
		padding-top: 0;
	}
}

.c-search__button-container:focus-within {
	outline: 3px solid color('lemon');
}

.c-search__input,
.c-search__select,
.c-search__button {
	padding: 0.6em 1em;
	border: 1px solid;
	border-radius: 3px;
	font-size: 1rem;
}

.c-search__input,
.c-search__select {
	box-sizing: border-box;
	width: 100%;
	background-color: #fff;
}

.c-search__select {
	display: block;
	width: 100%;
	min-width: 150px; // prevent arrow icon overlapping text
	max-width: 100%; /* useful when width is set to anything other than 100% */
	margin: 0;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	appearance: none;
	background-image: url("data:image/svg+xml,%3Csvg height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m5.58578644 3-3.29289322-3.29289322c-.39052429-.39052429-.39052429-1.02368927 0-1.41421356s1.02368927-.39052429 1.41421356 0l4 4c.39052429.39052429.39052429 1.02368927 0 1.41421356l-4 4c-.39052429.39052429-1.02368927.39052429-1.41421356 0s-.39052429-1.02368927 0-1.41421356z' fill='%23333' fill-rule='evenodd' transform='matrix(0 1 -1 0 11 3)'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	/* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
	background-position: right .7em top 50%;
	/* icon size, then gradient */
	background-size: 1em auto;

	/* Hide arrow icon in IE browsers */
	&::-ms-expand {
		display: none;
	}
}

.c-search__select--width-auto {
	width: auto;
}

.c-search__button {
	display: flex;
	align-items: center;
	justify-content: center;
	border-color: color('blue');
	background-color: color('blue');
	color: white;
	width: 100%;

	&:hover {
		background-color: white;
		color: color('blue');
	}

	svg {
		fill: currentColor;
	}
}

.c-search__button--width-auto {
	width: auto;
}

.c-search__button-text {
	margin-right: spacing(8);
}

.c-search__button-text--hide-at-sm {
	@include media-query('sm') {
		@include u-visually-hidden
	}
}

.c-search__link {
	font-size: $context--font-size-xs;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}
