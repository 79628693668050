// These styles are applied as a temporary measure to ensure the SNID account widget looks satisfactory in the Nature header
// These temporary styles should be removed and superceded when the Global Header and/or Nature Header components are updated by SNID and EDS

// SNID AW styles do not attempt to control the layout of the account widget links in a header (i.e. horizontal layout) so this is needed
.c-header__item--snid-account-widget {
	display: flex;
}

// SNID AW as per call with Laird, Steph, Ciaran 1/11/23 agreed that so few users have notifications
// at this time when a quick fix on mobile of hiding the notifications is ok until such time fixed
// properly the in the rollout of the Global Header component in EDS
.c-header__item--snid-account-widget .c-header__link--notifications {
	display: none;
}

@include media-query('sm') {
	.c-header__item--snid-account-widget .c-header__link--notifications {
		display: inline-block;
	}
}

// SNID AW does not set position relative on the notifications link therefore the notifications number is off-screen
.c-header__item--snid-account-widget .c-header__link--notifications {
	position: relative;
	margin-right: 10px;
}

// To ensure appropriate position relative to the notification links
.c-header__item--snid-account-widget .c-header__notification-count {
	top: 0;
	right: -18px;
}

// To ensure appropriate spacing with other header links in the Nature header design
@include media-query('sm') {
	.c-header__item--snid-account-widget .c-header__link--notifications + .c-account-nav {
		margin-left: 8px;
	}
}

// SNID AW styles for some reasons set content" unset on the SVG icon by default, this overrides that
.c-header__item--snid-account-widget .c-account-nav__anchor.eds-c-header__link::after {
	margin: 0;
	line-height: 1;
	height: 17px;
	content: url("data:image/svg+xml,%3Csvg role='img' padding='3px 0' aria-hidden='true' focusable='false' height='17' viewBox='0 0 17 17' width='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23222' d='m5.58578644 3-3.29289322-3.29289322c-.39052429-.39052429-.39052429-1.02368927 0-1.41421356s1.02368927-.39052429 1.41421356 0l4 4c.39052429.39052429.39052429 1.02368927 0 1.41421356l-4 4c-.39052429.39052429-1.02368927.39052429-1.41421356 0s-.39052429-1.02368927 0-1.41421356z' transform='matrix(0 1 -1 0 11 3)'%3E%3C/path%3E%3C/svg%3E");
	transform: rotate(0deg)
}

// SNID AW styles to rotate the SVG icon for account menu are being overridden by their own conflicting styles so this is needed
.c-header__item--snid-account-widget .c-account-nav__anchor.is-open::after {
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg) translateZ(0)
}


// Below styles needed to tweak existing Nature header styles to tighten up on mobile to allow for enough space to display My account satisfactorily
.c-header__container {
	padding: 0 4px;
}

.c-header__list {
	padding: 0 12px;
}

.c-header__menu .c-header__link {
	font-size: 14px;
}

.c-header__item--snid-account-widget .c-header__link {
	padding: 8px;
}

.c-header__menu--journal {
	margin-left: 0;
}

@include media-query('sm') {
	.c-header__container {
		padding: 0 16px;
	}

	.c-header__menu--journal {
		margin-left: -8px;
	}

	.c-header__menu .c-header__link {
		font-size: 16px;
	}

	.c-header__link--search {
		gap: 13px;
	}
}

