$card--border: none;
$card--box-shadow: none;
$card--dark-background: #29303c;
$card--dark-color: #e3e4e5;
$card--dark-title-color: white;
$card--font-family: inherit;
$card--font-size: .875rem;
$card--image-aspect-ratio: #{16 / 9};
$card--major-font-size: 1rem;
$card--major-title-heading: h2;
$card--padding-x: 0;
$card--padding: spacing(16);
$card--spacing: spacing(8);
$card--title-heading: h3;
$card--title-letter-spacing: -0.01875rem;

.c-card {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: space-between;
	font-size: $card--font-size;
	padding: $card--padding-x;
	border: $card--border;
	box-shadow: $card--box-shadow;
	overflow: hidden;
	color: inherit;
}

.c-card,
.c-card__layout,
.c-card__body {
	gap: spacing(8);
}

.c-card__layout {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1 1 auto;
}

.c-card__body {
	padding: $card--padding;
	flex: 1 1 auto;
}

.c-card__body .c-author-list {
	margin-top: auto;
}

.c-card__title {
	@include u-heading($card--title-heading);

	margin: 0;
	letter-spacing: $card--title-letter-spacing;
}

.c-card__summary {
	line-height: $context--line-height-tight;

	> p {
		margin-top: 0;
		margin-bottom: spacing(4);

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.c-card__image img {
	width: 100%;
}

.c-card__link:not(.c-card__link--no-block-link) {
	@include u-link-faux-block();
}

.c-card--flush {
	.c-card__body {
		padding: 0;
	}
}

.c-card--major {
	font-size: $card--major-font-size;

	.c-card__title {
		@include u-heading($card--major-title-heading);
	}
}

.c-card--dark {
	background-color: $card--dark-background;
	color: $card--dark-color;
	border-width: 0;

	.c-card__title {
		color: $card--dark-title-color;
	}
}

@mixin app-horizontal-card($type: null, $wide: false) {
	.c-card {
		flex-direction: column;
	}

	.c-card__image {
		@if $type == 'list' {
			flex: 1 1 40%;
			max-width: 230px;
		} @else if $type == 'split' {
			flex: 1 0 50%;
			max-width: 60%;
			overflow: hidden;
			min-height: 260px;

			img {
				object-fit: cover;
			}
		}
	}

	@include media-query('lg') {
		.c-card {
			flex-direction: row-reverse;
		}

		.c-card__layout {
			gap: spacing(24);
		}

		.c-card .c-meta {
			flex: 0 0 230px;
		}
	}
}

@mixin app-card-flex-layout($mobile: unset, $tablet: unset, $desktop: unset) {
	.c-card__layout {
		flex-direction: $mobile;

		@include media-query('md') {
			flex-direction: $tablet;
		}

		@include media-query('lg') {
			flex-direction: $desktop;
		}
	}
}
