.c-collections-sub-nav {
	background-color: greyscale(80);
	padding: spacing(16);
	margin-bottom: spacing(48);
	a {
		color: black;
	}
}

.c-collections-subnav--active {
	font-weight: $context--font-weight-bold;
	text-decoration: underline;
	-webkit-text-decoration-skip: skip;
	text-decoration-skip-ink: auto;
}

.app-collections {
	.c-list-description__item {
		margin-bottom: $global-list-description--wrapper-margin-bottom;
		margin-right: spacing(16);
		padding-right: spacing(16);
		border-right: 1px solid greyscale(70);

		@include media-query('lg') {
			margin-right: spacing(32);
			padding-right: spacing(32);
		}

		&:last-child {
			margin-right: spacing(0);
			padding-right: spacing(0);
			border-right: none;
		}

		&:last-child:not(:only-child) {
			margin-bottom: 0;
		}
	}
}

.app-collections.c-pill {
	font-size: 0.75rem;
}
