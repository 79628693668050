@font-face {
	font-family: 'Harding';
	src: url('/static/fonts/HardingText-Regular-Web.woff2') format('woff2'), url('/static/fonts/HardingText-Regular-Web.woff') format('woff');
	font-display: fallback;
}

@font-face {
	font-family: 'Harding';
	src: url('/static/fonts/HardingText-Bold-Web.woff2') format('woff2'), url('/static/fonts/HardingText-Bold-Web.woff') format('woff');
	font-display: swap;
	font-weight: bold;
}

@font-face {
	font-family: 'Harding';
	src: url('/static/fonts/HardingText-RegularItalic-Web.woff2') format('woff2'), url('/static/fonts/HardingText-RegularItalic-Web.woff') format('woff');
	font-display: swap;
	font-style: italic;
}

@font-face {
	font-family: 'Harding';
	src: url('/static/fonts/HardingText-BoldItalic-Web.woff2') format('woff2'), url('/static/fonts/HardingText-BoldItalic-Web.woff') format('woff');
	font-display: swap;
	font-weight: bold;
	font-style: italic;
}
