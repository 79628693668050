@import '../../../../node_modules/@springernature/brand-context/nature/scss/enhanced';

// This _should_ be in the nature context base/layout but it would mess up anything using mosaic
html {
	box-sizing: border-box;
}

html *,
html *::before,
html *::after {
	box-sizing: inherit;
}

body {
	background-color: white;
}

/**
 * Settings
 */

// Frontend Toolkits
@import '../../../../node_modules/@springernature/global-ad/scss/10-settings/nature';
@import '../../../../node_modules/@springernature/global-author-list/scss/10-settings/nature';
@import '../../../../node_modules/@springernature/global-banner/scss/10-settings/nature';
@import '../../../../node_modules/@springernature/global-breadcrumbs/scss/10-settings/nature';
@import '../../../../node_modules/@springernature/global-facet/scss/10-settings/nature';
@import '../../../../node_modules/@springernature/global-footer/scss/10-settings/default';
@import '../../../../node_modules/@springernature/global-layout-with-sidebar/scss/10-settings/nature';
@import '../../../../node_modules/@springernature/global-lists/scss/10-settings/nature';
@import '../../../../node_modules/@springernature/global-menu/scss/10-settings/default';
@import '../../../../node_modules/@springernature/global-meta/scss/10-settings/nature';
@import '../../../../node_modules/@springernature/global-pagination/scss/10-settings/default';
@import '../../../../node_modules/@springernature/global-pill/scss/10-settings/nature';
@import '../../../../node_modules/@springernature/global-popup/scss/10-settings/nature';
@import '../../../../node_modules/@springernature/global-skip-link/scss/10-settings/nature';
@import '../../../../node_modules/@springernature/global-status-message/scss/10-settings/default';

// Frontend Nature Toolkit
@import '../../node_modules/@springernature/nature-hero/scss/10-settings/hero';
@import '../../node_modules/@springernature/nature-section-heading/scss/10-settings/section-heading';

/**
 * Components
 */

// Frontend Global Toolkit
@import '../../../../node_modules/@springernature/global-ad/scss/50-components/ad';
@import '../../../../node_modules/@springernature/global-author-list/scss/50-components/author-list';
@import '../../../../node_modules/@springernature/global-banner/scss/50-components/banner';
@import '../../../../node_modules/@springernature/global-breadcrumbs/scss/50-components/breadcrumbs';
@import '../../../../node_modules/@springernature/global-facet/scss/50-components/facet';
@import '../../../../node_modules/@springernature/global-footer/scss/50-components/footer';
@import '../../../../node_modules/@springernature/global-facet/scss/50-components/facet-expander';
@import '../../../../node_modules/@springernature/global-layout-with-sidebar/scss/50-components/layout-with-sidebar';
@import '../../../../node_modules/@springernature/global-lists/scss/50-components/list-description';
@import '../../../../node_modules/@springernature/global-lists/scss/50-components/list-group';
@import '../../../../node_modules/@springernature/global-menu/scss/50-components/menu';
@import '../../../../node_modules/@springernature/global-meta/scss/50-components/meta';
@import '../../../../node_modules/@springernature/global-pagination/scss/50-components/pagination';
@import '../../../../node_modules/@springernature/global-pill/scss/50-components/pill';
@import '../../../../node_modules/@springernature/global-popup/scss/50-components/popup';
@import '../../../../node_modules/@springernature/global-skip-link/scss/50-components/skip-link';
@import '../../../../node_modules/@springernature/global-status-message/scss/50-components/status-message';

// Frontend Nature Toolkit
@import '../../node_modules/@springernature/nature-header/scss/50-components/header';
@import '../../node_modules/@springernature/nature-hero/scss/50-components/hero';
@import '../../node_modules/@springernature/nature-section-heading/scss/50-components/section-heading';

// Toolkit
@import '../../nature-150/toolkit/nature-collections/scss/50-components/collections';
@import '../../nature-150/toolkit/nature-collections/scss/50-components/sponsor';
@import '../../nature-150/toolkit/nature-search/scss/50-components/search';
@import '../../nature-150/toolkit/nature-video/scss/50-components/video';
@import '../journal/50-components/site-messages';

/**
 * App
 */

@import '../../nature-150/app/scss/ads';
@import '../../nature-150/app/scss/idp-account-widget';
@import '../../nature-150/app/scss/card';
@import '../../nature-150/app/scss/rows';
@import '../../nature-150/app/scss/latest-issue';
@import '../../nature-150/app/scss/primeview';
@import '../../nature-150/app/scss/header-overrides';

// Shared
@import '../../../../shared/scss/collection-editors';

/**
 * Utilities
 */

@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/buttons';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/clearfix';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/colors';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/container';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/display';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/flex';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/hiding';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/icons';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/layout';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/links';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/lists';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/overflow';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/positioning';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/style';
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/typography';
@import '../../../../node_modules/@springernature/brand-context/nature/scss/60-utilities/typography';
@import '../../nature-150/app/scss/utilities/hiding';

// Spacing must come last so it can override any margin/padding set on other utilities (e.g. container)
@import '../../../../node_modules/@springernature/brand-context/default/scss/60-utilities/spacing';

.grade-c-hide {
	display: block;
}
