// Styles Nature rows that use cards. Pages such as homepages, collection pages, search pages, magazine pages
// The styles arrange card components as well as augmenting the card styling in nature-150/app/scss/card.scss to suit the type of row
// Good pages to test include:
// 	http://local-www.nature.com:7890/nature
// 	http://local-www.nature.com:7890/ncomms
// 	http://local-www.nature.com:7890/srep
// 	http://local-www.nature.com:7890/latest-news
// 	http://local-www.nature.com:7890/collections/zzcpmcdkqp
// 	http://local-www.nature.com:7890/search?q=plants

$row--grid-gap: spacing(16);

@mixin app-row-item($keyline: true) {
	@if $keyline == true {
		@include u-keyline;
	}

	.c-card__summary {
		font-size: .875rem;
	}

	.c-card__image {
		aspect-ratio: auto;

		picture {
			width: 100%;
		}

		img {
			border: 1px solid greyscale(70);
			height: auto;
		}
	}
}

/*
------------
featured row
------------
*/

.app-featured-row {
	@include u-list-reset();
}

.app-featured-row__item {
	@include app-row-item();
}

.app-featured-row__item--current-issue {
	padding-top: $row--grid-gap;
	background-color: greyscale(90);

	@include media-query('xl', 'max') {
		width: 100%;
	}

	@include media-query('lg') {
		padding-top: 0;
		background-color: transparent;
	}

	.c-card {
		max-width: 250px;
		margin: auto;
	}
}

.app-featured-row {
	@include media-query('lg') {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: $row--grid-gap;
	}

	@include media-query('lg') {
		grid-template-columns: repeat(5, 1fr);
	}
}

.app-featured-row__item {
	&:not(.app-featured-row__item--current-issue) {
		@include media-query('lg', 'max') {
			@include app-horizontal-card($type: 'list');
			@include app-card-flex-layout(row-reverse, row-reverse, unset);
		}
	}
}

/*
------------
news row
------------
*/

.app-news-row {
	@include u-list-reset;
}

.app-news-row__item {
	@include app-row-item();

	.c-card__image {
		align-self: flex-start;
	}

	.c-meta {
		margin-top: auto;
	}
}

.app-news-row {
	@include media-query('md') {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: $row--grid-gap;
	}

	@include media-query('lg') {
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}
}

.app-news-row__item:not(.app-news-row__item--major) {
	@include app-card-flex-layout(row-reverse, row-reverse, column);
	@include media-query('md', 'max') {
		@include app-horizontal-card($type: 'list');
	}
}

.app-news-row__item--major {
	@include app-card-flex-layout(column, row-reverse, column);
	@include media-query('md') {
		grid-column: 1 / -1;
	}

	@include media-query('lg') {
		grid-column: 1 / 3;
		grid-row: 1 / -1;

		border-bottom: none; // Remove keyline
		padding-bottom: 0; // Remove keyline
		display: flex; // Stretch card content to height of grid container
	}

	@include media-query('md' 'lg', 'range') {
		@include app-horizontal-card($type: 'split');
	}

	.c-card__image {
		align-self: auto;

		img {
			border: none;
		}
	}
}

/*
------------
three item row
------------
*/

.app-three-item-row {
	@include u-list-reset;

	> * {
		max-width: none !important;
	}
}

.app-three-item-row__item {
	@include app-row-item();
}

.app-three-item-row {
	@include media-query('md') {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: $row--grid-gap;
	}
}

.app-three-item-row__item {
	@include media-query('md', 'max') {
		@include app-horizontal-card($type: 'list');
	}
}

/*
------------
reviews row
------------
*/

.app-reviews-row__grid {
	@include u-list-reset;
}

.app-reviews-row__item {
	@include app-row-item();
}

.app-reviews-row__side {
	@include u-list-reset();
}

.app-reviews-row__side-item {
	@include app-row-item();
}

.app-reviews-row {
	@include media-query('lg') {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-column-gap: $row--grid-gap;
	}
}

.app-reviews-row__main {
	@include media-query('lg') {
		grid-column: 1 / 5;
	}
}

.app-reviews-row__grid {
	@include media-query('lg') {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: $row--grid-gap;
	}
}

.app-reviews-row__item:not(.app-reviews-row__item--major) {
	@include media-query('lg', 'max') {
		@include app-horizontal-card($type: 'list');
		@include app-card-flex-layout(row-reverse, row-reverse, column);
	}
}

.app-reviews-row__item--major {
	@include media-query('md') {
		@include app-horizontal-card($type: 'split');
		@include app-card-flex-layout(column, row-reverse, row-reverse);

		grid-column: 1 / -1;
	}
}

.app-reviews-row__side {
	@include media-query('lg') {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
}

.app-reviews-row__side-item {
	@include media-query('lg') {
		flex: 1;
	}
}

.app-reviews-row__item:not(.app-reviews-row__item--major),
.app-reviews-row__item--major,
.app-reviews-row__side-item {
	.c-meta {
		margin-top: auto;
	}
}

/*
------------
article list row
------------
*/

.app-article-list-row {
	@include u-list-reset;
}

.app-article-list-row__mpu {
	display: flex;
	flex-wrap: wrap;

	@include media-query('sm') {
		flex-wrap: nowrap;
	}

	> .c-card {
		flex: 1 1 auto;

		@include media-query('sm') {
			margin-right: spacing(16);
		}
	}

	> .c-ad.c-ad--300x250 {
		padding-top: spacing(16);

		@include media-query('sm') {
			padding-top: 0;
		}
	}
}

.app-article-list-row__item {
	@include app-row-item();
	@include app-card-flex-layout(row-reverse, row-reverse, row-reverse);
	@include app-horizontal-card($type: 'list', $wide: true);
}

/*
------------
announcement list row
------------
*/

.app-announcement-list-row {
	@include u-list-reset;
}

.app-announcement-list-row__item {
	@include app-row-item();
	@include app-card-flex-layout(row-reverse, row-reverse, row-reverse);
	@include app-horizontal-card($type: 'list');

	.c-card__image {
		max-width: 300px;
	}

	.c-card__title {
		max-width: 750px;
	}

	.c-card__summary {
		max-width: 750px;
		font-size: $context--font-size-sm;
	}
}

/*
---------------
collections row
---------------
*/

.app-collections-row {
	@include u-list-reset();

	@include media-query('lg') {
		display: flex;
	}
}

.app-collections-row__item {
	@include app-row-item($keyline: true);

	@include media-query('md') {
		@include app-horizontal-card();
		@include app-card-flex-layout(column, row-reverse, row-reverse);
	}

	@include media-query('lg') {
		margin-right: $row--grid-gap;
		flex: 1 1 auto;
	}

	@include media-query('lg', 'max') {
		@include u-keyline;
	}

	.c-card__image {
		@include media-query('md') {
			flex: 1 1 auto;

			> img {
				max-height: 250px; // MPU height
			}
		}
	}

	.c-meta {
		margin-top: auto;
	}
}

/*
------------
native ad row
------------
*/

.app-native-ad {
	font-family: "Georgia";

	@include app-horizontal-card($type: 'list', $wide: true);
	@include app-card-flex-layout(row-reverse, row-reverse, row-reverse);
}
.app-native-ad__content {
	@include u-keyline($direction: top);
	@include app-row-item();
}

/*
------------
trending row
------------
*/

.app-trending-row {
	@include u-list-reset();

	@include media-query('md') {
		display: grid;
		grid-column-gap: $row--grid-gap;
		grid-template-columns: repeat(2, 1fr);
		row-gap: spacing(16);

		.app-trending-row__item {
			margin-bottom: 0;
		}
	}

	@include media-query('lg') {
		grid-template-columns: repeat(4, 1fr);
	}
}

.app-trending-row__item {
	@include u-keyline;
	text-align: center;

	@include media-query('md', 'max') {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.app-trending-row__link {
	@include u-link-inherit();
	display: block;
}

/*
------------
events row
------------
*/

.app-careers-row {
	@include media-query('md', 'max') {
		@include u-keyline;
	}

	@include media-query('lg') {
		display: flex;
	}
}

.app-careers-row__main {
	@include media-query('md') {
		display: flex;
	}

	@include media-query('lg') {
		padding-right: $row--grid-gap;
		flex-grow: 1;
	}
}

.app-careers-row__block {
	display: flex;
	flex-direction: column;

	@include media-query('md') {
		flex: 1 1 100%;
	}

	&:first-child {
		@include media-query('md') {
			padding-right: $row--grid-gap;
		}
	}

	&:only-child {
		@include media-query('md') {
			padding-right: 0;
		}
	}
}

.app-careers-row__listing {
	flex-grow: 1;

	.c-list-group__item h4 {
		line-height: 1.5; // temporary solution until standarising the font-sizes in headings
	}
}

/*
------------
subjects row
------------
*/

.app-subjects-row {
	@include u-list-reset;
}

.app-subjects-row__item {
	@include app-row-item();
}

.app-subjects-row {
	@include media-query('md') {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: $row--grid-gap;
	}

	@include media-query('lg') {
		grid-template-columns: repeat(5, 1fr);
	}
}

.app-subjects-row__item {
	@include app-card-flex-layout(row-reverse, row-reverse, column);
	@include media-query('md', 'max') {
		@include app-horizontal-card($type: 'list');
	}
}

/*
------------
sponsor row
------------
*/
.app-sponsor-row {
	@include u-list-reset();
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.app-sponsor-row__item {
	width: 100%;
	margin-bottom: spacing(48);
	text-align: center;
	padding: 0 spacing(32);
}

.app-sponsor-row__link {
	color: #626262;
	display: block;
}

.app-sponsor-row__label {
	font-size: $context--font-size-xs;
	line-height: 1.25;
	margin: 0 0 spacing(16);
}

.app-sponsor-row__img {
	max-width: 180px;
	max-height: 78px;
}

.app-sponsor-row__item:only-child {
	width: 100%;
	.app-sponsor-row__img {
    	max-width: 300px;
    	max-height: 130px;
    }
}

@include media-query('sm') {
	.app-sponsor-row__item {
	  width: calc(100% / 2);
	}
}

@include media-query('md') {
	.app-sponsor-row__item {
	  width: calc(100% / 3);
	}
}

@include media-query('lg') {
	.app-sponsor-row__item {
		width: calc(100% / 4);
	}
}

/*
------------
membership row
------------
*/

.app-membership-row {
	@include u-list-reset();
	display: flex;
	flex-wrap: wrap;
	border-top: 1px solid greyscale();
	margin-top: spacing(16);
	padding-top: spacing(16);
}

.app-membership-row__item {
	flex: 1 1 50%;
	color: greyscale(25); // #666
}

/*
------------
MPU in a row
------------
*/

.app-careers-row,
.app-article-list-row__mpu,
.app-collections-row {
	> .c-ad.c-ad--300x250 {
		flex: 1 0 300px;
	}
}
