.app-sponsor {
	@include u-keyline('top', 48px);
}

.app-sponsor__text {
	font-size: $context--font-size-xs;
	line-height: 1.2;
}

.app-sponsor__list {
	@include u-list-inline;
	display: flex;
	gap: spacing(16);
	font-size: $context--font-size-xs;
	margin-bottom: spacing(8);
	align-items: stretch;
	justify-content: flex-start;
	flex-wrap: wrap;

	@include media-query('lg') {
		& {
			flex-wrap: initial;
		}
	}
}

.app-sponsor__list li {
	display: flex;
	flex-direction: column;
	flex-basis: calc(100vw / 3 - 32px); // one third of the viewport width minus the page container padding

	> *:only-child {
		margin-top: auto;
	}

	@include media-query('lg') {
		& {
			flex-basis: initial;
		}
	}
}

.app-sponsor__list > li:nth-of-type(1n + 4) {
	margin-top: spacing(16);

	@include media-query('lg') {
		& {
			margin-top: 0;
		}
	}
}

.app-sponsor__link {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	> *:only-child {
		margin-top: auto;
	}

	@include media-query('lg') {
		& {
			justify-content: initial;
		}
	}
}

.app-sponsor__image {
	max-height: 75px;
}
