/*
Overrides for the SN Profile user account widget. Oscar makes a call to the SN Profile account widget endpoint, which returns the html, css and js for the widget.
Applications such as ours need to override the default styling of the widget to ensure correct for our brand.
Documentation for the SN Profile account widget: https://integration-example-app-qa.springernature.com/account-widget
*/

.c-account-nav__menu {
	background-color: #000 !important;
	border-bottom: 1px solid greyscale(5) !important;
	color: greyscale(90) !important;
	line-height: 1.2 !important;
	padding: spacing(16) !important;
}

@include media-query('sm', 'max') {
	.c-account-nav {
		position: initial !important;
	}

	.c-account-nav__menu {
		max-width: initial !important;
		width: 100% !important;
		left: 0 !important;
	}
}

.c-account-nav__menu-header {
	font-size: $context--font-size-xs !important;
	font-weight: $context--font-weight-bold !important;
	border-color: greyscale(70) !important;
	padding: spacing(8) spacing(8) spacing(16) !important;
	margin: 0 calc(#{spacing(8)} * -1) !important;
}

.c-account-nav__anchor.is-open::after {
	content: url("data:image/svg+xml,%3Csvg role='img' padding='3px 0' aria-hidden='true' focusable='false' height='17' viewBox='0 0 17 17' width='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23069' d='m5.58578644 3-3.29289322-3.29289322c-.39052429-.39052429-.39052429-1.02368927 0-1.41421356s1.02368927-.39052429 1.41421356 0l4 4c.39052429.39052429.39052429 1.02368927 0 1.41421356l-4 4c-.39052429.39052429-1.02368927.39052429-1.41421356 0s-.39052429-1.02368927 0-1.41421356z' transform='matrix(0 1 -1 0 11 3)'%3E%3C/path%3E%3C/svg%3E") !important;
}

.c-account-nav__menu-list {
	margin: 0 calc(#{spacing(8)} * -1) !important;
}

.c-account-nav__menu-item {
	padding: spacing(8) !important;
}

.c-account-nav__menu-item a {
	@include u-link-inherit();
	font-size: $context--font-size-xs !important;
	font-weight: $context--font-weight-bold !important;
}

.c-account-nav__menu-item a.is-open {
	color: color('blue') !important;
	background-color: greyscale(80) !important;
	border-radius: 2px !important;
	text-decoration: none !important;
}

.c-account-nav__menu-item:first-of-type {
	padding-top: spacing(16) !important;
}

.c-account-nav__menu-item:last-of-type {
	padding: spacing(8) !important;
}
