.app-primeview {
	position: relative;
	display: flex;
	width: 100%;
	background-color: greyscale(80);
	border: 1px solid greyscale(80);
	padding: spacing(8);
	transition: 0.25s ease, background-color 0.25s ease;
	&:hover {
		background-color: greyscale(70);
	}
}

.app-primeview__link {
	@include u-link-faux-block();
	@include u-link-underline()
}

.app-primeview__main {
	flex: 1 1 auto;
	min-width: 1px; // IE10/11 nested flexbox fix
}

.app-primeview__side {
	flex: 0 0 120px;
	max-width: 120px;
}
