.u-hide-sm-max {
	@include media-query('sm', 'max') {
		@include u-hide;
	}
}

.u-show-lg {
	display: none;
	@include media-query('lg') {
		display:block;
	}
}
